#footer {
  padding: 50px 0;
  .first {
    width: 400px;
    max-width: 100%;

    p {
      // font-family: "Satoshi";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 148%;
      /* or 27px */

      letter-spacing: -0.03em;

      color: rgba(255, 255, 255, 0.8);
    }
  }

  .logo {
    width: 240px;
    margin-bottom: 20px;
  }

  .quick-link {
    list-style: none;
    text-decoration: none;
    li {
      margin-bottom: 14px;
      a {
        // font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 148%;
        /* or 27px */

        letter-spacing: -0.03em;

        color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .newsletter {
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 89.5%;
      /* or 21px */

      letter-spacing: -0.03em;

      color: #ffffff;
      margin-bottom: 30px;
    }
    a {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 166% */

      color: rgba(255, 255, 255, 0.8);
    }
  }

  .social {
    margin-top: 30px;

    img {
      transition: transform .7s ease-in-out;
    }
    img:hover {
      transform: rotate(360deg);
      cursor: pointer;
    }

    
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 89.5%;
      /* or 21px */

      letter-spacing: -0.03em;

      color: #ffffff;
      margin-bottom: 30px;
    }
    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      li {
        img {
          height: 20px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #footer {
    padding: 60px 0 30px;
    border-top: 1px solid #80808042;

    .logo {
      width: 100px;
      margin-left: 38% ;
    }

    .first p {
      font-size: 16px;
      margin-bottom: 50px;
    }

    .quick-link {
      list-style: none;
      margin-bottom: 50px;
      margin-left: 35%;
  }
  .social ul{
    // padding-left: 100px;
    // padding-left: 35%;
  }

  }

  .newsletter{
    text-align: center;

  }
  .social{
    text-align: center;
  }
}

