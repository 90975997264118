@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');


html,
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  max-width: 100%;

  font: 400 16px/21px sans-serif;

  margin: 0;

  
  
}



#root {
  background: black;
  color: #d8d2d2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #00c08e;
}
a:hover {
  color: #bc1823 !important;
  cursor: pointer;
}

img {
  vertical-align: top;
  border-style: none;
  max-width: 100%;
  height: auto;
}

/* .containerxyz {
  height: 30px;
  width: 30px;
} */

 .top-right{
  position:absolute;
  width: 600px;
  height: 600px;
  left: 900px;
  top: -118px;
  border-radius: 0 0 500px 100%;

  background: rgba(255, 170, 170, 0.5);
  filter: blur(152px);
} 




@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl{
    max-width: 1140px;
  }
  /* .top-right{
    position:absolute;
    width: 600px;
    height: 600px;
    left: 900px;
    top: -118px;
    border-radius: 0 0 500px 100%;
  
    background: rgba(255, 170, 170, 0.5);
    filter: blur(152px);
  }  */
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

/*STAR CSS Start
________________________________________________________________________________*/
.m-a {
  margin: auto !important;
}
.mt-a {
  margin-top: auto !important;
}
.mb-a {
  margin-bottom: auto !important;
}
.ml-a {
  margin-left: auto !important;
}
.p-a {
  padding: auto !important;
}
.pt-a {
  padding-top: auto !important;
}
.pb-a {
  padding-bottom: auto !important;
}
.pl-a {
  padding-left: auto !important;
}
.pr-a {
  padding-right: auto !important;
}

.text-l {
  text-align: left !important;
}
.text-r {
  text-align: right !important;
}
.text-c {
  text-align: center !important;
}
.text-j {
  text-align: justify !important;
}
.margin-a {
  margin: auto !important;
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-flex {
  display: flex !important;
}
.display-f {
  display: flex !important;
}

.jc-sb {
  justify-content: space-between;
}
/* STAR CSS End
________________________________________________________________________________________*/

/* 
 * Custom Bootstrap Margin Padding
 * Author - Md Ismail Hossain
 * Git    - https://github.com/ismailcseku/Custom-Bootstrap-Margin-Padding
 * Web    - http://ismail-hossain.me/
 * Email  - ismailcseku@gmail.com
 */

/**
 * Table of Contents:
 *
 * 1   -> Basic margin padding
 * 1.1 -> Basic Margin
 * 1.2 -> Basic Padding
 * 1.3 -> Margin top
 * 1.4 -> Margin right
 * 1.5 -> Margin bottom
 * 1.6 -> Margin left
 * 1.7 -> Padding top
 * 1.8 -> Padding right
 * 1.9 -> Padding bottom
 * 1.10 -> Padding left
 * 2 -> lg: min-width:1200px
 * 3 -> md: max-width:1199px
 * 4 -> sm: max-width:991px
 * 5 -> xs: max-width 767px
 * 6 -> xxs: max-width 479px
 * ===============================================
 */
/* min even, max odd*/

/*
 * 1.1 -> Basic Margin
 * -----------------------------------------------
*/
.m-0 {
  margin: 0px !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-15 {
  margin: 15px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-25 {
  margin: 25px !important;
}
.m-30 {
  margin: 30px !important;
}
.m-40 {
  margin: 40px !important;
}
.m-50 {
  margin: 50px !important;
}
.m-60 {
  margin: 60px !important;
}
.m-70 {
  margin: 70px !important;
}
.m-80 {
  margin: 80px !important;
}
.m-90 {
  margin: 90px !important;
}
.m-100 {
  margin: 100px !important;
}
.m-110 {
  margin: 110px !important;
}
.m-120 {
  margin: 120px !important;
}
.m-130 {
  margin: 130px !important;
}
.m-140 {
  margin: 140px !important;
}
.m-150 {
  margin: 150px !important;
}
.m-160 {
  margin: 160px !important;
}
.m-170 {
  margin: 170px !important;
}
.m-180 {
  margin: 180px !important;
}
.m-190 {
  margin: 190px !important;
}
.m-200 {
  margin: 200px !important;
}

/*
 * 1.2 -> Basic Padding
 * -----------------------------------------------
*/
.p-0 {
  padding: 0 !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-60 {
  padding: 60px !important;
}
.p-70 {
  padding: 70px !important;
}
.p-80 {
  padding: 80px !important;
}
.p-90 {
  padding: 90px !important;
}
.p-100 {
  padding: 100px !important;
}
.p-110 {
  padding: 110px !important;
}
.p-120 {
  padding: 120px !important;
}
.p-130 {
  padding: 130px !important;
}
.p-140 {
  padding: 140px !important;
}
.p-150 {
  padding: 150px !important;
}
.p-160 {
  padding: 160px !important;
}
.p-170 {
  padding: 170px !important;
}
.p-180 {
  padding: 180px !important;
}
.p-190 {
  padding: 190px !important;
}
.p-200 {
  padding: 200px !important;
}

/*
 * 1.3 -> Margin top
 * -----------------------------------------------
*/
.mt-0 {
  margin-top: 0 !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-90 {
  margin-top: 90px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mt-110 {
  margin-top: 110px !important;
}
.mt-120 {
  margin-top: 120px !important;
}
.mt-130 {
  margin-top: 130px !important;
}
.mt-140 {
  margin-top: 140px !important;
}
.mt-150 {
  margin-top: 150px !important;
}
.mt-160 {
  margin-top: 160px !important;
}
.mt-170 {
  margin-top: 170px !important;
}
.mt-180 {
  margin-top: 180px !important;
}
.mt-190 {
  margin-top: 190px !important;
}
.mt-200 {
  margin-top: 200px !important;
}

/*
 * 1.4 -> Margin right
 * -----------------------------------------------
*/
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-80 {
  margin-right: 80px !important;
}
.mr-90 {
  margin-right: 90px !important;
}
.mr-100 {
  margin-right: 100px !important;
}
.mr-110 {
  margin-right: 110px !important;
}
.mr-120 {
  margin-right: 120px !important;
}
.mr-130 {
  margin-right: 130px !important;
}
.mr-140 {
  margin-right: 140px !important;
}
.mr-150 {
  margin-right: 150px !important;
}
.mr-160 {
  margin-right: 160px !important;
}
.mr-170 {
  margin-right: 170px !important;
}
.mr-180 {
  margin-right: 180px !important;
}
.mr-190 {
  margin-right: 190px !important;
}
.mr-200 {
  margin-right: 200px !important;
}

/*
 * 1.5 -> Margin bottom
 * -----------------------------------------------
*/
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-70 {
  margin-bottom: 70px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
.mb-90 {
  margin-bottom: 90px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}
.mb-110 {
  margin-bottom: 110px !important;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.mb-130 {
  margin-bottom: 130px !important;
}
.mb-140 {
  margin-bottom: 140px !important;
}
.mb-150 {
  margin-bottom: 150px !important;
}
.mb-160 {
  margin-bottom: 160px !important;
}
.mb-170 {
  margin-bottom: 170px !important;
}
.mb-180 {
  margin-bottom: 180px !important;
}
.mb-190 {
  margin-bottom: 190px !important;
}
.mb-200 {
  margin-bottom: 200px !important;
}

/*
 * 1.6 -> Margin left
 * -----------------------------------------------
*/
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-80 {
  margin-left: 80px !important;
}
.ml-90 {
  margin-left: 90px !important;
}
.ml-100 {
  margin-left: 100px !important;
}
.ml-110 {
  margin-left: 110px !important;
}
.ml-120 {
  margin-left: 120px !important;
}
.ml-130 {
  margin-left: 130px !important;
}
.ml-140 {
  margin-left: 140px !important;
}
.ml-150 {
  margin-left: 150px !important;
}
.ml-160 {
  margin-left: 160px !important;
}
.ml-170 {
  margin-left: 170px !important;
}
.ml-180 {
  margin-left: 180px !important;
}
.ml-190 {
  margin-left: 190px !important;
}
.ml-200 {
  margin-left: 200px !important;
}

/*
 * 1.7 -> Padding top
 * -----------------------------------------------
*/
.pt-0 {
  padding-top: 0 !important;
}
.pt-5 {
  padding-top: 5px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
.pt-70 {
  padding-top: 70px !important;
}
.pt-80 {
  padding-top: 80px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pt-110 {
  padding-top: 110px !important;
}
.pt-120 {
  padding-top: 120px !important;
}
.pt-130 {
  padding-top: 130px !important;
}
.pt-140 {
  padding-top: 140px !important;
}
.pt-150 {
  padding-top: 150px !important;
}
.pt-160 {
  padding-top: 160px !important;
}
.pt-170 {
  padding-top: 170px !important;
}
.pt-180 {
  padding-top: 180px !important;
}
.pt-190 {
  padding-top: 190px !important;
}
.pt-200 {
  padding-top: 200px !important;
}

/*
 * 1.8 -> Padding right
 * -----------------------------------------------
*/
.pr-0 {
  padding-right: 0 !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-60 {
  padding-right: 60px !important;
}
.pr-70 {
  padding-right: 70px !important;
}
.pr-80 {
  padding-right: 80px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pr-100 {
  padding-right: 100px !important;
}
.pr-110 {
  padding-right: 110px !important;
}
.pr-120 {
  padding-right: 120px !important;
}
.pr-130 {
  padding-right: 130px !important;
}
.pr-140 {
  padding-right: 140px !important;
}
.pr-150 {
  padding-right: 150px !important;
}
.pr-160 {
  padding-right: 160px !important;
}
.pr-170 {
  padding-right: 170px !important;
}
.pr-180 {
  padding-right: 180px !important;
}
.pr-190 {
  padding-right: 190px !important;
}
.pr-200 {
  padding-right: 200px !important;
}

/*
 * 1.9 -> Padding bottom
 * -----------------------------------------------
*/
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.pb-80 {
  padding-bottom: 80px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.pb-110 {
  padding-bottom: 110px !important;
}
.pb-120 {
  padding-bottom: 120px !important;
}
.pb-130 {
  padding-bottom: 130px !important;
}
.pb-140 {
  padding-bottom: 140px !important;
}
.pb-150 {
  padding-bottom: 150px !important;
}
.pb-160 {
  padding-bottom: 160px !important;
}
.pb-170 {
  padding-bottom: 170px !important;
}
.pb-180 {
  padding-bottom: 180px !important;
}
.pb-190 {
  padding-bottom: 190px !important;
}
.pb-200 {
  padding-bottom: 200px !important;
}

/*
 * 1.10 -> Padding left
 * -----------------------------------------------
*/
.pl-0 {
  padding-left: 0 !important;
}
.pl-5 {
  padding-left: 5px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
.pl-60 {
  padding-left: 60px !important;
}
.pl-70 {
  padding-left: 70px !important;
}
.pl-80 {
  padding-left: 80px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.pl-100 {
  padding-left: 100px !important;
}
.pl-110 {
  padding-left: 110px !important;
}
.pl-120 {
  padding-left: 120px !important;
}
.pl-130 {
  padding-left: 130px !important;
}
.pl-140 {
  padding-left: 140px !important;
}
.pl-150 {
  padding-left: 150px !important;
}
.pl-160 {
  padding-left: 160px !important;
}
.pl-170 {
  padding-left: 170px !important;
}
.pl-180 {
  padding-left: 180px !important;
}
.pl-190 {
  padding-left: 190px !important;
}
.pl-200 {
  padding-left: 200px !important;
}

/*
 * 2 -> lg: min-width:1200px
 * -----------------------------------------------
*/
@media (min-width: 1200px) {
  /*
 * 2.1 -> Basic Margin
 * -----------------------------------------------
*/
  .m-lg-0 {
    margin: 0px !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .m-lg-40 {
    margin: 40px !important;
  }
  .m-lg-50 {
    margin: 50px !important;
  }
  .m-lg-60 {
    margin: 60px !important;
  }
  .m-lg-70 {
    margin: 70px !important;
  }
  .m-lg-80 {
    margin: 80px !important;
  }
  .m-lg-90 {
    margin: 90px !important;
  }
  .m-lg-100 {
    margin: 100px !important;
  }
  .m-lg-110 {
    margin: 110px !important;
  }
  .m-lg-120 {
    margin: 120px !important;
  }
  .m-lg-130 {
    margin: 130px !important;
  }
  .m-lg-140 {
    margin: 140px !important;
  }
  .m-lg-150 {
    margin: 150px !important;
  }
  .m-lg-160 {
    margin: 160px !important;
  }
  .m-lg-170 {
    margin: 170px !important;
  }
  .m-lg-180 {
    margin: 180px !important;
  }
  .m-lg-190 {
    margin: 190px !important;
  }
  .m-lg-200 {
    margin: 200px !important;
  }

  /*
 * 2.2 -> Basic Padding
 * -----------------------------------------------
*/
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .p-lg-30 {
    padding: 30px !important;
  }
  .p-lg-40 {
    padding: 40px !important;
  }
  .p-lg-50 {
    padding: 50px !important;
  }
  .p-lg-60 {
    padding: 60px !important;
  }
  .p-lg-70 {
    padding: 70px !important;
  }
  .p-lg-80 {
    padding: 80px !important;
  }
  .p-lg-90 {
    padding: 90px !important;
  }
  .p-lg-100 {
    padding: 100px !important;
  }
  .p-lg-110 {
    padding: 110px !important;
  }
  .p-lg-120 {
    padding: 120px !important;
  }
  .p-lg-130 {
    padding: 130px !important;
  }
  .p-lg-140 {
    padding: 140px !important;
  }
  .p-lg-150 {
    padding: 150px !important;
  }
  .p-lg-160 {
    padding: 160px !important;
  }
  .p-lg-170 {
    padding: 170px !important;
  }
  .p-lg-180 {
    padding: 180px !important;
  }
  .p-lg-190 {
    padding: 190px !important;
  }
  .p-lg-200 {
    padding: 200px !important;
  }

  /*
 * 2.3 -> Margin top
 * -----------------------------------------------
*/
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-5 {
    margin-top: 5px !important;
  }
  .mt-lg-10 {
    margin-top: 10px !important;
  }
  .mt-lg-15 {
    margin-top: 15px !important;
  }
  .mt-lg-20 {
    margin-top: 20px !important;
  }
  .mt-lg-30 {
    margin-top: 30px !important;
  }
  .mt-lg-40 {
    margin-top: 40px !important;
  }
  .mt-lg-50 {
    margin-top: 50px !important;
  }
  .mt-lg-60 {
    margin-top: 60px !important;
  }
  .mt-lg-70 {
    margin-top: 70px !important;
  }
  .mt-lg-80 {
    margin-top: 80px !important;
  }
  .mt-lg-90 {
    margin-top: 90px !important;
  }
  .mt-lg-100 {
    margin-top: 100px !important;
  }
  .mt-lg-110 {
    margin-top: 110px !important;
  }
  .mt-lg-120 {
    margin-top: 120px !important;
  }
  .mt-lg-130 {
    margin-top: 130px !important;
  }
  .mt-lg-140 {
    margin-top: 140px !important;
  }
  .mt-lg-150 {
    margin-top: 150px !important;
  }
  .mt-lg-160 {
    margin-top: 160px !important;
  }
  .mt-lg-170 {
    margin-top: 170px !important;
  }
  .mt-lg-180 {
    margin-top: 180px !important;
  }
  .mt-lg-190 {
    margin-top: 190px !important;
  }
  .mt-lg-200 {
    margin-top: 200px !important;
  }

  /*
 * 2.4 -> Margin right
 * -----------------------------------------------
*/
  .mr-lg-0 {
    margin-right: 0 !important;
  }
  .mr-lg-5 {
    margin-right: 5px !important;
  }
  .mr-lg-10 {
    margin-right: 10px !important;
  }
  .mr-lg-15 {
    margin-right: 15px !important;
  }
  .mr-lg-20 {
    margin-right: 20px !important;
  }
  .mr-lg-30 {
    margin-right: 30px !important;
  }
  .mr-lg-40 {
    margin-right: 40px !important;
  }
  .mr-lg-50 {
    margin-right: 50px !important;
  }
  .mr-lg-60 {
    margin-right: 60px !important;
  }
  .mr-lg-70 {
    margin-right: 70px !important;
  }
  .mr-lg-80 {
    margin-right: 80px !important;
  }
  .mr-lg-90 {
    margin-right: 90px !important;
  }
  .mr-lg-100 {
    margin-right: 100px !important;
  }
  .mr-lg-110 {
    margin-right: 110px !important;
  }
  .mr-lg-120 {
    margin-right: 120px !important;
  }
  .mr-lg-130 {
    margin-right: 130px !important;
  }
  .mr-lg-140 {
    margin-right: 140px !important;
  }
  .mr-lg-150 {
    margin-right: 150px !important;
  }
  .mr-lg-160 {
    margin-right: 160px !important;
  }
  .mr-lg-170 {
    margin-right: 170px !important;
  }
  .mr-lg-180 {
    margin-right: 180px !important;
  }
  .mr-lg-190 {
    margin-right: 190px !important;
  }
  .mr-lg-200 {
    margin-right: 200px !important;
  }

  /*
 * 2.5 -> Margin bottom
 * -----------------------------------------------
*/
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
  .mb-lg-10 {
    margin-bottom: 10px !important;
  }
  .mb-lg-15 {
    margin-bottom: 15px !important;
  }
  .mb-lg-20 {
    margin-bottom: 20px !important;
  }
  .mb-lg-30 {
    margin-bottom: 30px !important;
  }
  .mb-lg-40 {
    margin-bottom: 40px !important;
  }
  .mb-lg-50 {
    margin-bottom: 50px !important;
  }
  .mb-lg-60 {
    margin-bottom: 60px !important;
  }
  .mb-lg-70 {
    margin-bottom: 70px !important;
  }
  .mb-lg-80 {
    margin-bottom: 80px !important;
  }
  .mb-lg-90 {
    margin-bottom: 90px !important;
  }
  .mb-lg-100 {
    margin-bottom: 100px !important;
  }
  .mb-lg-110 {
    margin-bottom: 110px !important;
  }
  .mb-lg-120 {
    margin-bottom: 120px !important;
  }
  .mb-lg-130 {
    margin-bottom: 130px !important;
  }
  .mb-lg-140 {
    margin-bottom: 140px !important;
  }
  .mb-lg-150 {
    margin-bottom: 150px !important;
  }
  .mb-lg-160 {
    margin-bottom: 160px !important;
  }
  .mb-lg-170 {
    margin-bottom: 170px !important;
  }
  .mb-lg-180 {
    margin-bottom: 180px !important;
  }
  .mb-lg-190 {
    margin-bottom: 190px !important;
  }
  .mb-lg-200 {
    margin-bottom: 200px !important;
  }

  /*
 * 2.6 -> Margin left
 * -----------------------------------------------
*/
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .ml-lg-5 {
    margin-left: 5px !important;
  }
  .ml-lg-10 {
    margin-left: 10px !important;
  }
  .ml-lg-15 {
    margin-left: 15px !important;
  }
  .ml-lg-20 {
    margin-left: 20px !important;
  }
  .ml-lg-30 {
    margin-left: 30px !important;
  }
  .ml-lg-40 {
    margin-left: 40px !important;
  }
  .ml-lg-50 {
    margin-left: 50px !important;
  }
  .ml-lg-60 {
    margin-left: 60px !important;
  }
  .ml-lg-70 {
    margin-left: 70px !important;
  }
  .ml-lg-80 {
    margin-left: 80px !important;
  }
  .ml-lg-90 {
    margin-left: 90px !important;
  }
  .ml-lg-100 {
    margin-left: 100px !important;
  }
  .ml-lg-110 {
    margin-left: 110px !important;
  }
  .ml-lg-120 {
    margin-left: 120px !important;
  }
  .ml-lg-130 {
    margin-left: 130px !important;
  }
  .ml-lg-140 {
    margin-left: 140px !important;
  }
  .ml-lg-150 {
    margin-left: 150px !important;
  }
  .ml-lg-160 {
    margin-left: 160px !important;
  }
  .ml-lg-170 {
    margin-left: 170px !important;
  }
  .ml-lg-180 {
    margin-left: 180px !important;
  }
  .ml-lg-190 {
    margin-left: 190px !important;
  }
  .ml-lg-200 {
    margin-left: 200px !important;
  }

  /*
 * 2.7 -> Padding top
 * -----------------------------------------------
*/
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-5 {
    padding-top: 5px !important;
  }
  .pt-lg-10 {
    padding-top: 10px !important;
  }
  .pt-lg-15 {
    padding-top: 15px !important;
  }
  .pt-lg-20 {
    padding-top: 20px !important;
  }
  .pt-lg-30 {
    padding-top: 30px !important;
  }
  .pt-lg-40 {
    padding-top: 40px !important;
  }
  .pt-lg-50 {
    padding-top: 50px !important;
  }
  .pt-lg-60 {
    padding-top: 60px !important;
  }
  .pt-lg-70 {
    padding-top: 70px !important;
  }
  .pt-lg-80 {
    padding-top: 80px !important;
  }
  .pt-lg-90 {
    padding-top: 90px !important;
  }
  .pt-lg-100 {
    padding-top: 100px !important;
  }
  .pt-lg-110 {
    padding-top: 110px !important;
  }
  .pt-lg-120 {
    padding-top: 120px !important;
  }
  .pt-lg-130 {
    padding-top: 130px !important;
  }
  .pt-lg-140 {
    padding-top: 140px !important;
  }
  .pt-lg-150 {
    padding-top: 150px !important;
  }
  .pt-lg-160 {
    padding-top: 160px !important;
  }
  .pt-lg-170 {
    padding-top: 170px !important;
  }
  .pt-lg-180 {
    padding-top: 180px !important;
  }
  .pt-lg-190 {
    padding-top: 190px !important;
  }
  .pt-lg-200 {
    padding-top: 200px !important;
  }

  /*
 * 2.8 -> Padding right
 * -----------------------------------------------
*/
  .pr-lg-0 {
    padding-right: 0 !important;
  }
  .pr-lg-5 {
    padding-right: 5px !important;
  }
  .pr-lg-10 {
    padding-right: 10px !important;
  }
  .pr-lg-15 {
    padding-right: 15px !important;
  }
  .pr-lg-20 {
    padding-right: 20px !important;
  }
  .pr-lg-30 {
    padding-right: 30px !important;
  }
  .pr-lg-40 {
    padding-right: 40px !important;
  }
  .pr-lg-50 {
    padding-right: 50px !important;
  }
  .pr-lg-60 {
    padding-right: 60px !important;
  }
  .pr-lg-70 {
    padding-right: 70px !important;
  }
  .pr-lg-80 {
    padding-right: 80px !important;
  }
  .pr-lg-90 {
    padding-right: 90px !important;
  }
  .pr-lg-100 {
    padding-right: 100px !important;
  }
  .pr-lg-110 {
    padding-right: 110px !important;
  }
  .pr-lg-120 {
    padding-right: 120px !important;
  }
  .pr-lg-130 {
    padding-right: 130px !important;
  }
  .pr-lg-140 {
    padding-right: 140px !important;
  }
  .pr-lg-150 {
    padding-right: 150px !important;
  }
  .pr-lg-160 {
    padding-right: 160px !important;
  }
  .pr-lg-170 {
    padding-right: 170px !important;
  }
  .pr-lg-180 {
    padding-right: 180px !important;
  }
  .pr-lg-190 {
    padding-right: 190px !important;
  }
  .pr-lg-200 {
    padding-right: 200px !important;
  }

  /*
 * 2.9 -> Padding bottom
 * -----------------------------------------------
*/
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
  .pb-lg-10 {
    padding-bottom: 10px !important;
  }
  .pb-lg-15 {
    padding-bottom: 15px !important;
  }
  .pb-lg-20 {
    padding-bottom: 20px !important;
  }
  .pb-lg-30 {
    padding-bottom: 30px !important;
  }
  .pb-lg-40 {
    padding-bottom: 40px !important;
  }
  .pb-lg-50 {
    padding-bottom: 50px !important;
  }
  .pb-lg-60 {
    padding-bottom: 60px !important;
  }
  .pb-lg-70 {
    padding-bottom: 70px !important;
  }
  .pb-lg-80 {
    padding-bottom: 80px !important;
  }
  .pb-lg-90 {
    padding-bottom: 90px !important;
  }
  .pb-lg-100 {
    padding-bottom: 100px !important;
  }
  .pb-lg-110 {
    padding-bottom: 110px !important;
  }
  .pb-lg-120 {
    padding-bottom: 120px !important;
  }
  .pb-lg-130 {
    padding-bottom: 130px !important;
  }
  .pb-lg-140 {
    padding-bottom: 140px !important;
  }
  .pb-lg-150 {
    padding-bottom: 150px !important;
  }
  .pb-lg-160 {
    padding-bottom: 160px !important;
  }
  .pb-lg-170 {
    padding-bottom: 170px !important;
  }
  .pb-lg-180 {
    padding-bottom: 180px !important;
  }
  .pb-lg-190 {
    padding-bottom: 190px !important;
  }
  .pb-lg-200 {
    padding-bottom: 200px !important;
  }

  /*
 * 2.10 -> Padding left
 * -----------------------------------------------
*/
  .pl-lg-0 {
    padding-left: 0 !important;
  }
  .pl-lg-5 {
    padding-left: 5px !important;
  }
  .pl-lg-10 {
    padding-left: 10px !important;
  }
  .pl-lg-15 {
    padding-left: 15px !important;
  }
  .pl-lg-20 {
    padding-left: 20px !important;
  }
  .pl-lg-30 {
    padding-left: 30px !important;
  }
  .pl-lg-40 {
    padding-left: 40px !important;
  }
  .pl-lg-50 {
    padding-left: 50px !important;
  }
  .pl-lg-60 {
    padding-left: 60px !important;
  }
  .pl-lg-70 {
    padding-left: 70px !important;
  }
  .pl-lg-80 {
    padding-left: 80px !important;
  }
  .pl-lg-90 {
    padding-left: 90px !important;
  }
  .pl-lg-100 {
    padding-left: 100px !important;
  }
  .pl-lg-110 {
    padding-left: 110px !important;
  }
  .pl-lg-120 {
    padding-left: 120px !important;
  }
  .pl-lg-130 {
    padding-left: 130px !important;
  }
  .pl-lg-140 {
    padding-left: 140px !important;
  }
  .pl-lg-150 {
    padding-left: 150px !important;
  }
  .pl-lg-160 {
    padding-left: 160px !important;
  }
  .pl-lg-170 {
    padding-left: 170px !important;
  }
  .pl-lg-180 {
    padding-left: 180px !important;
  }
  .pl-lg-190 {
    padding-left: 190px !important;
  }
  .pl-lg-200 {
    padding-left: 200px !important;
  }
}

/*
 * 3 -> md: max-width:1199px
 * -----------------------------------------------
*/
@media (max-width: 1199px) {
  /*
 * 3.1 -> Basic Margin
 * -----------------------------------------------
*/
  .m-md-0 {
    margin: 0px !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .m-md-70 {
    margin: 70px !important;
  }
  .m-md-80 {
    margin: 80px !important;
  }
  .m-md-90 {
    margin: 90px !important;
  }
  .m-md-100 {
    margin: 100px !important;
  }
  .m-md-110 {
    margin: 110px !important;
  }
  .m-md-120 {
    margin: 120px !important;
  }
  .m-md-130 {
    margin: 130px !important;
  }
  .m-md-140 {
    margin: 140px !important;
  }
  .m-md-150 {
    margin: 150px !important;
  }
  .m-md-160 {
    margin: 160px !important;
  }
  .m-md-170 {
    margin: 170px !important;
  }
  .m-md-180 {
    margin: 180px !important;
  }
  .m-md-190 {
    margin: 190px !important;
  }
  .m-md-200 {
    margin: 200px !important;
  }

  /*
 * 3.2 -> Basic Padding
 * -----------------------------------------------
*/
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .p-md-60 {
    padding: 60px !important;
  }
  .p-md-70 {
    padding: 70px !important;
  }
  .p-md-80 {
    padding: 80px !important;
  }
  .p-md-90 {
    padding: 90px !important;
  }
  .p-md-100 {
    padding: 100px !important;
  }
  .p-md-110 {
    padding: 110px !important;
  }
  .p-md-120 {
    padding: 120px !important;
  }
  .p-md-130 {
    padding: 130px !important;
  }
  .p-md-140 {
    padding: 140px !important;
  }
  .p-md-150 {
    padding: 150px !important;
  }
  .p-md-160 {
    padding: 160px !important;
  }
  .p-md-170 {
    padding: 170px !important;
  }
  .p-md-180 {
    padding: 180px !important;
  }
  .p-md-190 {
    padding: 190px !important;
  }
  .p-md-200 {
    padding: 200px !important;
  }

  /*
 * 3.3 -> Margin top
 * -----------------------------------------------
*/
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
  .mt-md-50 {
    margin-top: 50px !important;
  }
  .mt-md-60 {
    margin-top: 60px !important;
  }
  .mt-md-70 {
    margin-top: 70px !important;
  }
  .mt-md-80 {
    margin-top: 80px !important;
  }
  .mt-md-90 {
    margin-top: 90px !important;
  }
  .mt-md-100 {
    margin-top: 100px !important;
  }
  .mt-md-110 {
    margin-top: 110px !important;
  }
  .mt-md-120 {
    margin-top: 120px !important;
  }
  .mt-md-130 {
    margin-top: 130px !important;
  }
  .mt-md-140 {
    margin-top: 140px !important;
  }
  .mt-md-150 {
    margin-top: 150px !important;
  }
  .mt-md-160 {
    margin-top: 160px !important;
  }
  .mt-md-170 {
    margin-top: 170px !important;
  }
  .mt-md-180 {
    margin-top: 180px !important;
  }
  .mt-md-190 {
    margin-top: 190px !important;
  }
  .mt-md-200 {
    margin-top: 200px !important;
  }

  /*
 * 3.4 -> Margin right
 * -----------------------------------------------
*/
  .mr-md-0 {
    margin-right: 0 !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mr-md-10 {
    margin-right: 10px !important;
  }
  .mr-md-15 {
    margin-right: 15px !important;
  }
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .mr-md-40 {
    margin-right: 40px !important;
  }
  .mr-md-50 {
    margin-right: 50px !important;
  }
  .mr-md-60 {
    margin-right: 60px !important;
  }
  .mr-md-70 {
    margin-right: 70px !important;
  }
  .mr-md-80 {
    margin-right: 80px !important;
  }
  .mr-md-90 {
    margin-right: 90px !important;
  }
  .mr-md-100 {
    margin-right: 100px !important;
  }
  .mr-md-110 {
    margin-right: 110px !important;
  }
  .mr-md-120 {
    margin-right: 120px !important;
  }
  .mr-md-130 {
    margin-right: 130px !important;
  }
  .mr-md-140 {
    margin-right: 140px !important;
  }
  .mr-md-150 {
    margin-right: 150px !important;
  }
  .mr-md-160 {
    margin-right: 160px !important;
  }
  .mr-md-170 {
    margin-right: 170px !important;
  }
  .mr-md-180 {
    margin-right: 180px !important;
  }
  .mr-md-190 {
    margin-right: 190px !important;
  }
  .mr-md-200 {
    margin-right: 200px !important;
  }

  /*
 * 3.5 -> Margin bottom
 * -----------------------------------------------
*/
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
  .mb-md-50 {
    margin-bottom: 50px !important;
  }
  .mb-md-60 {
    margin-bottom: 60px !important;
  }
  .mb-md-70 {
    margin-bottom: 70px !important;
  }
  .mb-md-80 {
    margin-bottom: 80px !important;
  }
  .mb-md-90 {
    margin-bottom: 90px !important;
  }
  .mb-md-100 {
    margin-bottom: 100px !important;
  }
  .mb-md-110 {
    margin-bottom: 110px !important;
  }
  .mb-md-120 {
    margin-bottom: 120px !important;
  }
  .mb-md-130 {
    margin-bottom: 130px !important;
  }
  .mb-md-140 {
    margin-bottom: 140px !important;
  }
  .mb-md-150 {
    margin-bottom: 150px !important;
  }
  .mb-md-160 {
    margin-bottom: 160px !important;
  }
  .mb-md-170 {
    margin-bottom: 170px !important;
  }
  .mb-md-180 {
    margin-bottom: 180px !important;
  }
  .mb-md-190 {
    margin-bottom: 190px !important;
  }
  .mb-md-200 {
    margin-bottom: 200px !important;
  }

  /*
 * 3.6 -> Margin left
 * -----------------------------------------------
*/
  .ml-md-0 {
    margin-left: 0 !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .ml-md-10 {
    margin-left: 10px !important;
  }
  .ml-md-15 {
    margin-left: 15px !important;
  }
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
  .ml-md-40 {
    margin-left: 40px !important;
  }
  .ml-md-50 {
    margin-left: 50px !important;
  }
  .ml-md-60 {
    margin-left: 60px !important;
  }
  .ml-md-70 {
    margin-left: 70px !important;
  }
  .ml-md-80 {
    margin-left: 80px !important;
  }
  .ml-md-90 {
    margin-left: 90px !important;
  }
  .ml-md-100 {
    margin-left: 100px !important;
  }
  .ml-md-110 {
    margin-left: 110px !important;
  }
  .ml-md-120 {
    margin-left: 120px !important;
  }
  .ml-md-130 {
    margin-left: 130px !important;
  }
  .ml-md-140 {
    margin-left: 140px !important;
  }
  .ml-md-150 {
    margin-left: 150px !important;
  }
  .ml-md-160 {
    margin-left: 160px !important;
  }
  .ml-md-170 {
    margin-left: 170px !important;
  }
  .ml-md-180 {
    margin-left: 180px !important;
  }
  .ml-md-190 {
    margin-left: 190px !important;
  }
  .ml-md-200 {
    margin-left: 200px !important;
  }

  /*
 * 3.7 -> Padding top
 * -----------------------------------------------
*/
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pt-md-10 {
    padding-top: 10px !important;
  }
  .pt-md-15 {
    padding-top: 15px !important;
  }
  .pt-md-20 {
    padding-top: 20px !important;
  }
  .pt-md-30 {
    padding-top: 30px !important;
  }
  .pt-md-40 {
    padding-top: 40px !important;
  }
  .pt-md-50 {
    padding-top: 50px !important;
  }
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pt-md-70 {
    padding-top: 70px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pt-md-90 {
    padding-top: 90px !important;
  }
  .pt-md-100 {
    padding-top: 100px !important;
  }
  .pt-md-110 {
    padding-top: 110px !important;
  }
  .pt-md-120 {
    padding-top: 120px !important;
  }
  .pt-md-130 {
    padding-top: 130px !important;
  }
  .pt-md-140 {
    padding-top: 140px !important;
  }
  .pt-md-150 {
    padding-top: 150px !important;
  }
  .pt-md-160 {
    padding-top: 160px !important;
  }
  .pt-md-170 {
    padding-top: 170px !important;
  }
  .pt-md-180 {
    padding-top: 180px !important;
  }
  .pt-md-190 {
    padding-top: 190px !important;
  }
  .pt-md-200 {
    padding-top: 200px !important;
  }

  /*
 * 3.8 -> Padding right
 * -----------------------------------------------
*/
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .pr-md-5 {
    padding-right: 5px !important;
  }
  .pr-md-10 {
    padding-right: 10px !important;
  }
  .pr-md-15 {
    padding-right: 15px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .pr-md-30 {
    padding-right: 30px !important;
  }
  .pr-md-40 {
    padding-right: 40px !important;
  }
  .pr-md-50 {
    padding-right: 50px !important;
  }
  .pr-md-60 {
    padding-right: 60px !important;
  }
  .pr-md-70 {
    padding-right: 70px !important;
  }
  .pr-md-80 {
    padding-right: 80px !important;
  }
  .pr-md-90 {
    padding-right: 90px !important;
  }
  .pr-md-100 {
    padding-right: 100px !important;
  }
  .pr-md-110 {
    padding-right: 110px !important;
  }
  .pr-md-120 {
    padding-right: 120px !important;
  }
  .pr-md-130 {
    padding-right: 130px !important;
  }
  .pr-md-140 {
    padding-right: 140px !important;
  }
  .pr-md-150 {
    padding-right: 150px !important;
  }
  .pr-md-160 {
    padding-right: 160px !important;
  }
  .pr-md-170 {
    padding-right: 170px !important;
  }
  .pr-md-180 {
    padding-right: 180px !important;
  }
  .pr-md-190 {
    padding-right: 190px !important;
  }
  .pr-md-200 {
    padding-right: 200px !important;
  }

  /*
 * 3.9 -> Padding bottom
 * -----------------------------------------------
*/
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px !important;
  }
  .pb-md-15 {
    padding-bottom: 15px !important;
  }
  .pb-md-20 {
    padding-bottom: 20px !important;
  }
  .pb-md-30 {
    padding-bottom: 30px !important;
  }
  .pb-md-40 {
    padding-bottom: 40px !important;
  }
  .pb-md-50 {
    padding-bottom: 50px !important;
  }
  .pb-md-60 {
    padding-bottom: 60px !important;
  }
  .pb-md-70 {
    padding-bottom: 70px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .pb-md-90 {
    padding-bottom: 90px !important;
  }
  .pb-md-100 {
    padding-bottom: 100px !important;
  }
  .pb-md-110 {
    padding-bottom: 110px !important;
  }
  .pb-md-120 {
    padding-bottom: 120px !important;
  }
  .pb-md-130 {
    padding-bottom: 130px !important;
  }
  .pb-md-140 {
    padding-bottom: 140px !important;
  }
  .pb-md-150 {
    padding-bottom: 150px !important;
  }
  .pb-md-160 {
    padding-bottom: 160px !important;
  }
  .pb-md-170 {
    padding-bottom: 170px !important;
  }
  .pb-md-180 {
    padding-bottom: 180px !important;
  }
  .pb-md-190 {
    padding-bottom: 190px !important;
  }
  .pb-md-200 {
    padding-bottom: 200px !important;
  }

  /*
 * 3.10 -> Padding left
 * -----------------------------------------------
*/
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pl-md-5 {
    padding-left: 5px !important;
  }
  .pl-md-10 {
    padding-left: 10px !important;
  }
  .pl-md-15 {
    padding-left: 15px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .pl-md-30 {
    padding-left: 30px !important;
  }
  .pl-md-40 {
    padding-left: 40px !important;
  }
  .pl-md-50 {
    padding-left: 50px !important;
  }
  .pl-md-60 {
    padding-left: 60px !important;
  }
  .pl-md-70 {
    padding-left: 70px !important;
  }
  .pl-md-80 {
    padding-left: 80px !important;
  }
  .pl-md-90 {
    padding-left: 90px !important;
  }
  .pl-md-100 {
    padding-left: 100px !important;
  }
  .pl-md-110 {
    padding-left: 110px !important;
  }
  .pl-md-120 {
    padding-left: 120px !important;
  }
  .pl-md-130 {
    padding-left: 130px !important;
  }
  .pl-md-140 {
    padding-left: 140px !important;
  }
  .pl-md-150 {
    padding-left: 150px !important;
  }
  .pl-md-160 {
    padding-left: 160px !important;
  }
  .pl-md-170 {
    padding-left: 170px !important;
  }
  .pl-md-180 {
    padding-left: 180px !important;
  }
  .pl-md-190 {
    padding-left: 190px !important;
  }
  .pl-md-200 {
    padding-left: 200px !important;
  }
}

/*
 * 4 -> sm: max-width:991px
 * -----------------------------------------------
*/
@media (max-width: 991px) {
  /*
 * 4.1 -> Basic Margin
 * -----------------------------------------------
*/
  .m-sm-0 {
    margin: 0px !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .m-sm-70 {
    margin: 70px !important;
  }
  .m-sm-80 {
    margin: 80px !important;
  }
  .m-sm-90 {
    margin: 90px !important;
  }
  .m-sm-100 {
    margin: 100px !important;
  }
  .m-sm-110 {
    margin: 110px !important;
  }
  .m-sm-120 {
    margin: 120px !important;
  }
  .m-sm-130 {
    margin: 130px !important;
  }
  .m-sm-140 {
    margin: 140px !important;
  }
  .m-sm-150 {
    margin: 150px !important;
  }
  .m-sm-160 {
    margin: 160px !important;
  }
  .m-sm-170 {
    margin: 170px !important;
  }
  .m-sm-180 {
    margin: 180px !important;
  }
  .m-sm-190 {
    margin: 190px !important;
  }
  .m-sm-200 {
    margin: 200px !important;
  }

  /*
 * 4.2 -> Basic Padding
 * -----------------------------------------------
*/
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .p-sm-70 {
    padding: 70px !important;
  }
  .p-sm-80 {
    padding: 80px !important;
  }
  .p-sm-90 {
    padding: 90px !important;
  }
  .p-sm-100 {
    padding: 100px !important;
  }
  .p-sm-110 {
    padding: 110px !important;
  }
  .p-sm-120 {
    padding: 120px !important;
  }
  .p-sm-130 {
    padding: 130px !important;
  }
  .p-sm-140 {
    padding: 140px !important;
  }
  .p-sm-150 {
    padding: 150px !important;
  }
  .p-sm-160 {
    padding: 160px !important;
  }
  .p-sm-170 {
    padding: 170px !important;
  }
  .p-sm-180 {
    padding: 180px !important;
  }
  .p-sm-190 {
    padding: 190px !important;
  }
  .p-sm-200 {
    padding: 200px !important;
  }

  /*
 * 4.3 -> Margin top
 * -----------------------------------------------
*/
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .mt-sm-50 {
    margin-top: 50px !important;
  }
  .mt-sm-60 {
    margin-top: 60px !important;
  }
  .mt-sm-70 {
    margin-top: 70px !important;
  }
  .mt-sm-80 {
    margin-top: 80px !important;
  }
  .mt-sm-90 {
    margin-top: 90px !important;
  }
  .mt-sm-100 {
    margin-top: 100px !important;
  }
  .mt-sm-110 {
    margin-top: 110px !important;
  }
  .mt-sm-120 {
    margin-top: 120px !important;
  }
  .mt-sm-130 {
    margin-top: 130px !important;
  }
  .mt-sm-140 {
    margin-top: 140px !important;
  }
  .mt-sm-150 {
    margin-top: 150px !important;
  }
  .mt-sm-160 {
    margin-top: 160px !important;
  }
  .mt-sm-170 {
    margin-top: 170px !important;
  }
  .mt-sm-180 {
    margin-top: 180px !important;
  }
  .mt-sm-190 {
    margin-top: 190px !important;
  }
  .mt-sm-200 {
    margin-top: 200px !important;
  }

  /*
 * 4.4 -> Margin right
 * -----------------------------------------------
*/
  .mr-sm-0 {
    margin-right: 0 !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mr-sm-15 {
    margin-right: 15px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
  .mr-sm-50 {
    margin-right: 50px !important;
  }
  .mr-sm-60 {
    margin-right: 60px !important;
  }
  .mr-sm-70 {
    margin-right: 70px !important;
  }
  .mr-sm-80 {
    margin-right: 80px !important;
  }
  .mr-sm-90 {
    margin-right: 90px !important;
  }
  .mr-sm-100 {
    margin-right: 100px !important;
  }
  .mr-sm-110 {
    margin-right: 110px !important;
  }
  .mr-sm-120 {
    margin-right: 120px !important;
  }
  .mr-sm-130 {
    margin-right: 130px !important;
  }
  .mr-sm-140 {
    margin-right: 140px !important;
  }
  .mr-sm-150 {
    margin-right: 150px !important;
  }
  .mr-sm-160 {
    margin-right: 160px !important;
  }
  .mr-sm-170 {
    margin-right: 170px !important;
  }
  .mr-sm-180 {
    margin-right: 180px !important;
  }
  .mr-sm-190 {
    margin-right: 190px !important;
  }
  .mr-sm-200 {
    margin-right: 200px !important;
  }

  /*
 * 4.5 -> Margin bottom
 * -----------------------------------------------
*/
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .mb-sm-50 {
    margin-bottom: 50px !important;
  }
  .mb-sm-60 {
    margin-bottom: 60px !important;
  }
  .mb-sm-70 {
    margin-bottom: 70px !important;
  }
  .mb-sm-80 {
    margin-bottom: 80px !important;
  }
  .mb-sm-90 {
    margin-bottom: 90px !important;
  }
  .mb-sm-100 {
    margin-bottom: 100px !important;
  }
  .mb-sm-110 {
    margin-bottom: 110px !important;
  }
  .mb-sm-120 {
    margin-bottom: 120px !important;
  }
  .mb-sm-130 {
    margin-bottom: 130px !important;
  }
  .mb-sm-140 {
    margin-bottom: 140px !important;
  }
  .mb-sm-150 {
    margin-bottom: 150px !important;
  }
  .mb-sm-160 {
    margin-bottom: 160px !important;
  }
  .mb-sm-170 {
    margin-bottom: 170px !important;
  }
  .mb-sm-180 {
    margin-bottom: 180px !important;
  }
  .mb-sm-190 {
    margin-bottom: 190px !important;
  }
  .mb-sm-200 {
    margin-bottom: 200px !important;
  }

  /*
 * 4.6 -> Margin left
 * -----------------------------------------------
*/
  .ml-sm-0 {
    margin-left: 0 !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .ml-sm-15 {
    margin-left: 15px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
  .ml-sm-50 {
    margin-left: 50px !important;
  }
  .ml-sm-60 {
    margin-left: 60px !important;
  }
  .ml-sm-70 {
    margin-left: 70px !important;
  }
  .ml-sm-80 {
    margin-left: 80px !important;
  }
  .ml-sm-90 {
    margin-left: 90px !important;
  }
  .ml-sm-100 {
    margin-left: 100px !important;
  }
  .ml-sm-110 {
    margin-left: 110px !important;
  }
  .ml-sm-120 {
    margin-left: 120px !important;
  }
  .ml-sm-130 {
    margin-left: 130px !important;
  }
  .ml-sm-140 {
    margin-left: 140px !important;
  }
  .ml-sm-150 {
    margin-left: 150px !important;
  }
  .ml-sm-160 {
    margin-left: 160px !important;
  }
  .ml-sm-170 {
    margin-left: 170px !important;
  }
  .ml-sm-180 {
    margin-left: 180px !important;
  }
  .ml-sm-190 {
    margin-left: 190px !important;
  }
  .ml-sm-200 {
    margin-left: 200px !important;
  }

  /*
 * 4.7 -> Padding top
 * -----------------------------------------------
*/
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pt-sm-10 {
    padding-top: 10px !important;
  }
  .pt-sm-15 {
    padding-top: 15px !important;
  }
  .pt-sm-20 {
    padding-top: 20px !important;
  }
  .pt-sm-30 {
    padding-top: 30px !important;
  }
  .pt-sm-40 {
    padding-top: 40px !important;
  }
  .pt-sm-50 {
    padding-top: 50px !important;
  }
  .pt-sm-60 {
    padding-top: 60px !important;
  }
  .pt-sm-70 {
    padding-top: 70px !important;
  }
  .pt-sm-80 {
    padding-top: 80px !important;
  }
  .pt-sm-90 {
    padding-top: 90px !important;
  }
  .pt-sm-100 {
    padding-top: 100px !important;
  }
  .pt-sm-110 {
    padding-top: 110px !important;
  }
  .pt-sm-120 {
    padding-top: 120px !important;
  }
  .pt-sm-130 {
    padding-top: 130px !important;
  }
  .pt-sm-140 {
    padding-top: 140px !important;
  }
  .pt-sm-150 {
    padding-top: 150px !important;
  }
  .pt-sm-160 {
    padding-top: 160px !important;
  }
  .pt-sm-170 {
    padding-top: 170px !important;
  }
  .pt-sm-180 {
    padding-top: 180px !important;
  }
  .pt-sm-190 {
    padding-top: 190px !important;
  }
  .pt-sm-200 {
    padding-top: 200px !important;
  }

  /*
 * 4.8 -> Padding right
 * -----------------------------------------------
*/
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .pr-sm-5 {
    padding-right: 5px !important;
  }
  .pr-sm-10 {
    padding-right: 10px !important;
  }
  .pr-sm-15 {
    padding-right: 15px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .pr-sm-40 {
    padding-right: 40px !important;
  }
  .pr-sm-50 {
    padding-right: 50px !important;
  }
  .pr-sm-60 {
    padding-right: 60px !important;
  }
  .pr-sm-70 {
    padding-right: 70px !important;
  }
  .pr-sm-80 {
    padding-right: 80px !important;
  }
  .pr-sm-90 {
    padding-right: 90px !important;
  }
  .pr-sm-100 {
    padding-right: 100px !important;
  }
  .pr-sm-110 {
    padding-right: 110px !important;
  }
  .pr-sm-120 {
    padding-right: 120px !important;
  }
  .pr-sm-130 {
    padding-right: 130px !important;
  }
  .pr-sm-140 {
    padding-right: 140px !important;
  }
  .pr-sm-150 {
    padding-right: 150px !important;
  }
  .pr-sm-160 {
    padding-right: 160px !important;
  }
  .pr-sm-170 {
    padding-right: 170px !important;
  }
  .pr-sm-180 {
    padding-right: 180px !important;
  }
  .pr-sm-190 {
    padding-right: 190px !important;
  }
  .pr-sm-200 {
    padding-right: 200px !important;
  }

  /*
 * 4.9 -> Padding bottom
 * -----------------------------------------------
*/
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px !important;
  }
  .pb-sm-15 {
    padding-bottom: 15px !important;
  }
  .pb-sm-20 {
    padding-bottom: 20px !important;
  }
  .pb-sm-30 {
    padding-bottom: 30px !important;
  }
  .pb-sm-40 {
    padding-bottom: 40px !important;
  }
  .pb-sm-50 {
    padding-bottom: 50px !important;
  }
  .pb-sm-60 {
    padding-bottom: 60px !important;
  }
  .pb-sm-70 {
    padding-bottom: 70px !important;
  }
  .pb-sm-80 {
    padding-bottom: 80px !important;
  }
  .pb-sm-90 {
    padding-bottom: 90px !important;
  }
  .pb-sm-100 {
    padding-bottom: 100px !important;
  }
  .pb-sm-110 {
    padding-bottom: 110px !important;
  }
  .pb-sm-120 {
    padding-bottom: 120px !important;
  }
  .pb-sm-130 {
    padding-bottom: 130px !important;
  }
  .pb-sm-140 {
    padding-bottom: 140px !important;
  }
  .pb-sm-150 {
    padding-bottom: 150px !important;
  }
  .pb-sm-160 {
    padding-bottom: 160px !important;
  }
  .pb-sm-170 {
    padding-bottom: 170px !important;
  }
  .pb-sm-180 {
    padding-bottom: 180px !important;
  }
  .pb-sm-190 {
    padding-bottom: 190px !important;
  }
  .pb-sm-200 {
    padding-bottom: 200px !important;
  }

  /*
 * 4.10 -> Padding left
 * -----------------------------------------------
*/
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pl-sm-5 {
    padding-left: 5px !important;
  }
  .pl-sm-10 {
    padding-left: 10px !important;
  }
  .pl-sm-15 {
    padding-left: 15px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .pl-sm-40 {
    padding-left: 40px !important;
  }
  .pl-sm-50 {
    padding-left: 50px !important;
  }
  .pl-sm-60 {
    padding-left: 60px !important;
  }
  .pl-sm-70 {
    padding-left: 70px !important;
  }
  .pl-sm-80 {
    padding-left: 80px !important;
  }
  .pl-sm-90 {
    padding-left: 90px !important;
  }
  .pl-sm-100 {
    padding-left: 100px !important;
  }
  .pl-sm-110 {
    padding-left: 110px !important;
  }
  .pl-sm-120 {
    padding-left: 120px !important;
  }
  .pl-sm-130 {
    padding-left: 130px !important;
  }
  .pl-sm-140 {
    padding-left: 140px !important;
  }
  .pl-sm-150 {
    padding-left: 150px !important;
  }
  .pl-sm-160 {
    padding-left: 160px !important;
  }
  .pl-sm-170 {
    padding-left: 170px !important;
  }
  .pl-sm-180 {
    padding-left: 180px !important;
  }
  .pl-sm-190 {
    padding-left: 190px !important;
  }
  .pl-sm-200 {
    padding-left: 200px !important;
  }
}

/*
 * 5 -> xs: max-width 767px
 * -----------------------------------------------
*/
@media (max-width: 767px) {
  /*
 * 5.1 -> Basic Margin
 * -----------------------------------------------
*/
  .m-xs-0 {
    margin: 0px !important;
  }
  .m-xs-5 {
    margin: 5px !important;
  }
  .m-xs-10 {
    margin: 10px !important;
  }
  .m-xs-15 {
    margin: 15px !important;
  }
  .m-xs-20 {
    margin: 20px !important;
  }
  .m-xs-30 {
    margin: 30px !important;
  }
  .m-xs-40 {
    margin: 40px !important;
  }
  .m-xs-50 {
    margin: 50px !important;
  }
  .m-xs-60 {
    margin: 60px !important;
  }
  .m-xs-70 {
    margin: 70px !important;
  }
  .m-xs-80 {
    margin: 80px !important;
  }
  .m-xs-90 {
    margin: 90px !important;
  }
  .m-xs-100 {
    margin: 100px !important;
  }
  .m-xs-110 {
    margin: 110px !important;
  }
  .m-xs-120 {
    margin: 120px !important;
  }
  .m-xs-130 {
    margin: 130px !important;
  }
  .m-xs-140 {
    margin: 140px !important;
  }
  .m-xs-150 {
    margin: 150px !important;
  }
  .m-xs-160 {
    margin: 160px !important;
  }
  .m-xs-170 {
    margin: 170px !important;
  }
  .m-xs-180 {
    margin: 180px !important;
  }
  .m-xs-190 {
    margin: 190px !important;
  }
  .m-xs-200 {
    margin: 200px !important;
  }

  /*
 * 5.2 -> Basic Padding
 * -----------------------------------------------
*/
  .p-xs-0 {
    padding: 0 !important;
  }
  .p-xs-5 {
    padding: 5px !important;
  }
  .p-xs-10 {
    padding: 10px !important;
  }
  .p-xs-15 {
    padding: 15px !important;
  }
  .p-xs-20 {
    padding: 20px !important;
  }
  .p-xs-30 {
    padding: 30px !important;
  }
  .p-xs-40 {
    padding: 40px !important;
  }
  .p-xs-50 {
    padding: 50px !important;
  }
  .p-xs-60 {
    padding: 60px !important;
  }
  .p-xs-70 {
    padding: 70px !important;
  }
  .p-xs-80 {
    padding: 80px !important;
  }
  .p-xs-90 {
    padding: 90px !important;
  }
  .p-xs-100 {
    padding: 100px !important;
  }
  .p-xs-110 {
    padding: 110px !important;
  }
  .p-xs-120 {
    padding: 120px !important;
  }
  .p-xs-130 {
    padding: 130px !important;
  }
  .p-xs-140 {
    padding: 140px !important;
  }
  .p-xs-150 {
    padding: 150px !important;
  }
  .p-xs-160 {
    padding: 160px !important;
  }
  .p-xs-170 {
    padding: 170px !important;
  }
  .p-xs-180 {
    padding: 180px !important;
  }
  .p-xs-190 {
    padding: 190px !important;
  }
  .p-xs-200 {
    padding: 200px !important;
  }

  /*
 * 5.3 -> Margin top
 * -----------------------------------------------
*/
  .mt-xs-0 {
    margin-top: 0 !important;
  }
  .mt-xs-5 {
    margin-top: 5px !important;
  }
  .mt-xs-10 {
    margin-top: 10px !important;
  }
  .mt-xs-15 {
    margin-top: 15px !important;
  }
  .mt-xs-20 {
    margin-top: 20px !important;
  }
  .mt-xs-30 {
    margin-top: 30px !important;
  }
  .mt-xs-40 {
    margin-top: 40px !important;
  }
  .mt-xs-50 {
    margin-top: 50px !important;
  }
  .mt-xs-60 {
    margin-top: 60px !important;
  }
  .mt-xs-70 {
    margin-top: 70px !important;
  }
  .mt-xs-80 {
    margin-top: 80px !important;
  }
  .mt-xs-90 {
    margin-top: 90px !important;
  }
  .mt-xs-100 {
    margin-top: 100px !important;
  }
  .mt-xs-110 {
    margin-top: 110px !important;
  }
  .mt-xs-120 {
    margin-top: 120px !important;
  }
  .mt-xs-130 {
    margin-top: 130px !important;
  }
  .mt-xs-140 {
    margin-top: 140px !important;
  }
  .mt-xs-150 {
    margin-top: 150px !important;
  }
  .mt-xs-160 {
    margin-top: 160px !important;
  }
  .mt-xs-170 {
    margin-top: 170px !important;
  }
  .mt-xs-180 {
    margin-top: 180px !important;
  }
  .mt-xs-190 {
    margin-top: 190px !important;
  }
  .mt-xs-200 {
    margin-top: 200px !important;
  }

  /*
 * 5.4 -> Margin right
 * -----------------------------------------------
*/
  .mr-xs-0 {
    margin-right: 0 !important;
  }
  .mr-xs-5 {
    margin-right: 5px !important;
  }
  .mr-xs-10 {
    margin-right: 10px !important;
  }
  .mr-xs-15 {
    margin-right: 15px !important;
  }
  .mr-xs-20 {
    margin-right: 20px !important;
  }
  .mr-xs-30 {
    margin-right: 30px !important;
  }
  .mr-xs-40 {
    margin-right: 40px !important;
  }
  .mr-xs-50 {
    margin-right: 50px !important;
  }
  .mr-xs-60 {
    margin-right: 60px !important;
  }
  .mr-xs-70 {
    margin-right: 70px !important;
  }
  .mr-xs-80 {
    margin-right: 80px !important;
  }
  .mr-xs-90 {
    margin-right: 90px !important;
  }
  .mr-xs-100 {
    margin-right: 100px !important;
  }
  .mr-xs-110 {
    margin-right: 110px !important;
  }
  .mr-xs-120 {
    margin-right: 120px !important;
  }
  .mr-xs-130 {
    margin-right: 130px !important;
  }
  .mr-xs-140 {
    margin-right: 140px !important;
  }
  .mr-xs-150 {
    margin-right: 150px !important;
  }
  .mr-xs-160 {
    margin-right: 160px !important;
  }
  .mr-xs-170 {
    margin-right: 170px !important;
  }
  .mr-xs-180 {
    margin-right: 180px !important;
  }
  .mr-xs-190 {
    margin-right: 190px !important;
  }
  .mr-xs-200 {
    margin-right: 200px !important;
  }

  /*
 * 5.5 -> Margin bottom
 * -----------------------------------------------
*/
  .mb-xs-0 {
    margin-bottom: 0 !important;
  }
  .mb-xs-5 {
    margin-bottom: 5px !important;
  }
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }
  .mb-xs-15 {
    margin-bottom: 15px !important;
  }
  .mb-xs-20 {
    margin-bottom: 20px !important;
  }
  .mb-xs-30 {
    margin-bottom: 30px !important;
  }
  .mb-xs-40 {
    margin-bottom: 40px !important;
  }
  .mb-xs-50 {
    margin-bottom: 50px !important;
  }
  .mb-xs-60 {
    margin-bottom: 60px !important;
  }
  .mb-xs-70 {
    margin-bottom: 70px !important;
  }
  .mb-xs-80 {
    margin-bottom: 80px !important;
  }
  .mb-xs-90 {
    margin-bottom: 90px !important;
  }
  .mb-xs-100 {
    margin-bottom: 100px !important;
  }
  .mb-xs-110 {
    margin-bottom: 110px !important;
  }
  .mb-xs-120 {
    margin-bottom: 120px !important;
  }
  .mb-xs-130 {
    margin-bottom: 130px !important;
  }
  .mb-xs-140 {
    margin-bottom: 140px !important;
  }
  .mb-xs-150 {
    margin-bottom: 150px !important;
  }
  .mb-xs-160 {
    margin-bottom: 160px !important;
  }
  .mb-xs-170 {
    margin-bottom: 170px !important;
  }
  .mb-xs-180 {
    margin-bottom: 180px !important;
  }
  .mb-xs-190 {
    margin-bottom: 190px !important;
  }
  .mb-xs-200 {
    margin-bottom: 200px !important;
  }

  /*
 * 5.6 -> Margin left
 * -----------------------------------------------
*/
  .ml-xs-0 {
    margin-left: 0 !important;
  }
  .ml-xs-5 {
    margin-left: 5px !important;
  }
  .ml-xs-10 {
    margin-left: 10px !important;
  }
  .ml-xs-15 {
    margin-left: 15px !important;
  }
  .ml-xs-20 {
    margin-left: 20px !important;
  }
  .ml-xs-30 {
    margin-left: 30px !important;
  }
  .ml-xs-40 {
    margin-left: 40px !important;
  }
  .ml-xs-50 {
    margin-left: 50px !important;
  }
  .ml-xs-60 {
    margin-left: 60px !important;
  }
  .ml-xs-70 {
    margin-left: 70px !important;
  }
  .ml-xs-80 {
    margin-left: 80px !important;
  }
  .ml-xs-90 {
    margin-left: 90px !important;
  }
  .ml-xs-100 {
    margin-left: 100px !important;
  }
  .ml-xs-110 {
    margin-left: 110px !important;
  }
  .ml-xs-120 {
    margin-left: 120px !important;
  }
  .ml-xs-130 {
    margin-left: 130px !important;
  }
  .ml-xs-140 {
    margin-left: 140px !important;
  }
  .ml-xs-150 {
    margin-left: 150px !important;
  }
  .ml-xs-160 {
    margin-left: 160px !important;
  }
  .ml-xs-170 {
    margin-left: 170px !important;
  }
  .ml-xs-180 {
    margin-left: 180px !important;
  }
  .ml-xs-190 {
    margin-left: 190px !important;
  }
  .ml-xs-200 {
    margin-left: 200px !important;
  }

  /*
 * 5.7 -> Padding top
 * -----------------------------------------------
*/
  .pt-xs-0 {
    padding-top: 0 !important;
  }
  .pt-xs-5 {
    padding-top: 5px !important;
  }
  .pt-xs-10 {
    padding-top: 10px !important;
  }
  .pt-xs-15 {
    padding-top: 15px !important;
  }
  .pt-xs-20 {
    padding-top: 20px !important;
  }
  .pt-xs-30 {
    padding-top: 30px !important;
  }
  .pt-xs-40 {
    padding-top: 40px !important;
  }
  .pt-xs-50 {
    padding-top: 50px !important;
  }
  .pt-xs-60 {
    padding-top: 60px !important;
  }
  .pt-xs-70 {
    padding-top: 70px !important;
  }
  .pt-xs-80 {
    padding-top: 80px !important;
  }
  .pt-xs-90 {
    padding-top: 90px !important;
  }
  .pt-xs-100 {
    padding-top: 100px !important;
  }
  .pt-xs-110 {
    padding-top: 110px !important;
  }
  .pt-xs-120 {
    padding-top: 120px !important;
  }
  .pt-xs-130 {
    padding-top: 130px !important;
  }
  .pt-xs-140 {
    padding-top: 140px !important;
  }
  .pt-xs-150 {
    padding-top: 150px !important;
  }
  .pt-xs-160 {
    padding-top: 160px !important;
  }
  .pt-xs-170 {
    padding-top: 170px !important;
  }
  .pt-xs-180 {
    padding-top: 180px !important;
  }
  .pt-xs-190 {
    padding-top: 190px !important;
  }
  .pt-xs-200 {
    padding-top: 200px !important;
  }

  /*
 * 5.8 -> Padding right
 * -----------------------------------------------
*/
  .pr-xs-0 {
    padding-right: 0 !important;
  }
  .pr-xs-5 {
    padding-right: 5px !important;
  }
  .pr-xs-10 {
    padding-right: 10px !important;
  }
  .pr-xs-15 {
    padding-right: 15px !important;
  }
  .pr-xs-20 {
    padding-right: 20px !important;
  }
  .pr-xs-30 {
    padding-right: 30px !important;
  }
  .pr-xs-40 {
    padding-right: 40px !important;
  }
  .pr-xs-50 {
    padding-right: 50px !important;
  }
  .pr-xs-60 {
    padding-right: 60px !important;
  }
  .pr-xs-70 {
    padding-right: 70px !important;
  }
  .pr-xs-80 {
    padding-right: 80px !important;
  }
  .pr-xs-90 {
    padding-right: 90px !important;
  }
  .pr-xs-100 {
    padding-right: 100px !important;
  }
  .pr-xs-110 {
    padding-right: 110px !important;
  }
  .pr-xs-120 {
    padding-right: 120px !important;
  }
  .pr-xs-130 {
    padding-right: 130px !important;
  }
  .pr-xs-140 {
    padding-right: 140px !important;
  }
  .pr-xs-150 {
    padding-right: 150px !important;
  }
  .pr-xs-160 {
    padding-right: 160px !important;
  }
  .pr-xs-170 {
    padding-right: 170px !important;
  }
  .pr-xs-180 {
    padding-right: 180px !important;
  }
  .pr-xs-190 {
    padding-right: 190px !important;
  }
  .pr-xs-200 {
    padding-right: 200px !important;
  }

  /*
 * 5.9 -> Padding bottom
 * -----------------------------------------------
*/
  .pb-xs-0 {
    padding-bottom: 0 !important;
  }
  .pb-xs-5 {
    padding-bottom: 5px !important;
  }
  .pb-xs-10 {
    padding-bottom: 10px !important;
  }
  .pb-xs-15 {
    padding-bottom: 15px !important;
  }
  .pb-xs-20 {
    padding-bottom: 20px !important;
  }
  .pb-xs-30 {
    padding-bottom: 30px !important;
  }
  .pb-xs-40 {
    padding-bottom: 40px !important;
  }
  .pb-xs-50 {
    padding-bottom: 50px !important;
  }
  .pb-xs-60 {
    padding-bottom: 60px !important;
  }
  .pb-xs-70 {
    padding-bottom: 70px !important;
  }
  .pb-xs-80 {
    padding-bottom: 80px !important;
  }
  .pb-xs-90 {
    padding-bottom: 90px !important;
  }
  .pb-xs-100 {
    padding-bottom: 100px !important;
  }
  .pb-xs-110 {
    padding-bottom: 110px !important;
  }
  .pb-xs-120 {
    padding-bottom: 120px !important;
  }
  .pb-xs-130 {
    padding-bottom: 130px !important;
  }
  .pb-xs-140 {
    padding-bottom: 140px !important;
  }
  .pb-xs-150 {
    padding-bottom: 150px !important;
  }
  .pb-xs-160 {
    padding-bottom: 160px !important;
  }
  .pb-xs-170 {
    padding-bottom: 170px !important;
  }
  .pb-xs-180 {
    padding-bottom: 180px !important;
  }
  .pb-xs-190 {
    padding-bottom: 190px !important;
  }
  .pb-xs-200 {
    padding-bottom: 200px !important;
  }

  /*
 * 5.10 -> Padding left
 * -----------------------------------------------
*/
  .pl-xs-0 {
    padding-left: 0 !important;
  }
  .pl-xs-5 {
    padding-left: 5px !important;
  }
  .pl-xs-10 {
    padding-left: 10px !important;
  }
  .pl-xs-15 {
    padding-left: 15px !important;
  }
  .pl-xs-20 {
    padding-left: 20px !important;
  }
  .pl-xs-30 {
    padding-left: 30px !important;
  }
  .pl-xs-40 {
    padding-left: 40px !important;
  }
  .pl-xs-50 {
    padding-left: 50px !important;
  }
  .pl-xs-60 {
    padding-left: 60px !important;
  }
  .pl-xs-70 {
    padding-left: 70px !important;
  }
  .pl-xs-80 {
    padding-left: 80px !important;
  }
  .pl-xs-90 {
    padding-left: 90px !important;
  }
  .pl-xs-100 {
    padding-left: 100px !important;
  }
  .pl-xs-110 {
    padding-left: 110px !important;
  }
  .pl-xs-120 {
    padding-left: 120px !important;
  }
  .pl-xs-130 {
    padding-left: 130px !important;
  }
  .pl-xs-140 {
    padding-left: 140px !important;
  }
  .pl-xs-150 {
    padding-left: 150px !important;
  }
  .pl-xs-160 {
    padding-left: 160px !important;
  }
  .pl-xs-170 {
    padding-left: 170px !important;
  }
  .pl-xs-180 {
    padding-left: 180px !important;
  }
  .pl-xs-190 {
    padding-left: 190px !important;
  }
  .pl-xs-200 {
    padding-left: 200px !important;
  }
}

/*
 * 6 -> xxs: max-width 479px
 * -----------------------------------------------
*/
@media (max-width: 479px) {
  /*
 * 6.1 -> Basic Margin
 * -----------------------------------------------
*/
.top-right{
  display:none!important;
}
  .m-xxs-0 {
    margin: 0px !important;
  }
  .m-xxs-5 {
    margin: 5px !important;
  }
  .m-xxs-10 {
    margin: 10px !important;
  }
  .m-xxs-15 {
    margin: 15px !important;
  }
  .m-xxs-20 {
    margin: 20px !important;
  }
  .m-xxs-30 {
    margin: 30px !important;
  }
  .m-xxs-40 {
    margin: 40px !important;
  }
  .m-xxs-50 {
    margin: 50px !important;
  }
  .m-xxs-60 {
    margin: 60px !important;
  }
  .m-xxs-70 {
    margin: 70px !important;
  }
  .m-xxs-80 {
    margin: 80px !important;
  }
  .m-xxs-90 {
    margin: 90px !important;
  }
  .m-xxs-100 {
    margin: 100px !important;
  }
  .m-xxs-110 {
    margin: 110px !important;
  }
  .m-xxs-120 {
    margin: 120px !important;
  }
  .m-xxs-130 {
    margin: 130px !important;
  }
  .m-xxs-140 {
    margin: 140px !important;
  }
  .m-xxs-150 {
    margin: 150px !important;
  }
  .m-xxs-160 {
    margin: 160px !important;
  }
  .m-xxs-170 {
    margin: 170px !important;
  }
  .m-xxs-180 {
    margin: 180px !important;
  }
  .m-xxs-190 {
    margin: 190px !important;
  }
  .m-xxs-200 {
    margin: 200px !important;
  }

  /*
 * 6.2 -> Basic Padding
 * -----------------------------------------------
*/
  .p-xxs-0 {
    padding: 0 !important;
  }
  .p-xxs-5 {
    padding: 5px !important;
  }
  .p-xxs-10 {
    padding: 10px !important;
  }
  .p-xxs-15 {
    padding: 15px !important;
  }
  .p-xxs-20 {
    padding: 20px !important;
  }
  .p-xxs-30 {
    padding: 30px !important;
  }
  .p-xxs-40 {
    padding: 40px !important;
  }
  .p-xxs-50 {
    padding: 50px !important;
  }
  .p-xxs-60 {
    padding: 60px !important;
  }
  .p-xxs-70 {
    padding: 70px !important;
  }
  .p-xxs-80 {
    padding: 80px !important;
  }
  .p-xxs-90 {
    padding: 90px !important;
  }
  .p-xxs-100 {
    padding: 100px !important;
  }
  .p-xxs-110 {
    padding: 110px !important;
  }
  .p-xxs-120 {
    padding: 120px !important;
  }
  .p-xxs-130 {
    padding: 130px !important;
  }
  .p-xxs-140 {
    padding: 140px !important;
  }
  .p-xxs-150 {
    padding: 150px !important;
  }
  .p-xxs-160 {
    padding: 160px !important;
  }
  .p-xxs-170 {
    padding: 170px !important;
  }
  .p-xxs-180 {
    padding: 180px !important;
  }
  .p-xxs-190 {
    padding: 190px !important;
  }
  .p-xxs-200 {
    padding: 200px !important;
  }

  /*
 * 6.3 -> Margin top
 * -----------------------------------------------
*/
  .mt-xxs-0 {
    margin-top: 0 !important;
  }
  .mt-xxs-5 {
    margin-top: 5px !important;
  }
  .mt-xxs-10 {
    margin-top: 10px !important;
  }
  .mt-xxs-15 {
    margin-top: 15px !important;
  }
  .mt-xxs-20 {
    margin-top: 20px !important;
  }
  .mt-xxs-30 {
    margin-top: 30px !important;
  }
  .mt-xxs-40 {
    margin-top: 40px !important;
  }
  .mt-xxs-50 {
    margin-top: 50px !important;
  }
  .mt-xxs-60 {
    margin-top: 60px !important;
  }
  .mt-xxs-70 {
    margin-top: 70px !important;
  }
  .mt-xxs-80 {
    margin-top: 80px !important;
  }
  .mt-xxs-90 {
    margin-top: 90px !important;
  }
  .mt-xxs-100 {
    margin-top: 100px !important;
  }
  .mt-xxs-110 {
    margin-top: 110px !important;
  }
  .mt-xxs-120 {
    margin-top: 120px !important;
  }
  .mt-xxs-130 {
    margin-top: 130px !important;
  }
  .mt-xxs-140 {
    margin-top: 140px !important;
  }
  .mt-xxs-150 {
    margin-top: 150px !important;
  }
  .mt-xxs-160 {
    margin-top: 160px !important;
  }
  .mt-xxs-170 {
    margin-top: 170px !important;
  }
  .mt-xxs-180 {
    margin-top: 180px !important;
  }
  .mt-xxs-190 {
    margin-top: 190px !important;
  }
  .mt-xxs-200 {
    margin-top: 200px !important;
  }

  /*
 * 6.4 -> Margin right
 * -----------------------------------------------
*/
  .mr-xxs-0 {
    margin-right: 0 !important;
  }
  .mr-xxs-5 {
    margin-right: 5px !important;
  }
  .mr-xxs-10 {
    margin-right: 10px !important;
  }
  .mr-xxs-15 {
    margin-right: 15px !important;
  }
  .mr-xxs-20 {
    margin-right: 20px !important;
  }
  .mr-xxs-30 {
    margin-right: 30px !important;
  }
  .mr-xxs-40 {
    margin-right: 40px !important;
  }
  .mr-xxs-50 {
    margin-right: 50px !important;
  }
  .mr-xxs-60 {
    margin-right: 60px !important;
  }
  .mr-xxs-70 {
    margin-right: 70px !important;
  }
  .mr-xxs-80 {
    margin-right: 80px !important;
  }
  .mr-xxs-90 {
    margin-right: 90px !important;
  }
  .mr-xxs-100 {
    margin-right: 100px !important;
  }
  .mr-xxs-110 {
    margin-right: 110px !important;
  }
  .mr-xxs-120 {
    margin-right: 120px !important;
  }
  .mr-xxs-130 {
    margin-right: 130px !important;
  }
  .mr-xxs-140 {
    margin-right: 140px !important;
  }
  .mr-xxs-150 {
    margin-right: 150px !important;
  }
  .mr-xxs-160 {
    margin-right: 160px !important;
  }
  .mr-xxs-170 {
    margin-right: 170px !important;
  }
  .mr-xxs-180 {
    margin-right: 180px !important;
  }
  .mr-xxs-190 {
    margin-right: 190px !important;
  }
  .mr-xxs-200 {
    margin-right: 200px !important;
  }

  /*
 * 6.5 -> Margin bottom
 * -----------------------------------------------
*/
  .mb-xxs-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxs-5 {
    margin-bottom: 5px !important;
  }
  .mb-xxs-10 {
    margin-bottom: 10px !important;
  }
  .mb-xxs-15 {
    margin-bottom: 15px !important;
  }
  .mb-xxs-20 {
    margin-bottom: 20px !important;
  }
  .mb-xxs-30 {
    margin-bottom: 30px !important;
  }
  .mb-xxs-40 {
    margin-bottom: 40px !important;
  }
  .mb-xxs-50 {
    margin-bottom: 50px !important;
  }
  .mb-xxs-60 {
    margin-bottom: 60px !important;
  }
  .mb-xxs-70 {
    margin-bottom: 70px !important;
  }
  .mb-xxs-80 {
    margin-bottom: 80px !important;
  }
  .mb-xxs-90 {
    margin-bottom: 90px !important;
  }
  .mb-xxs-100 {
    margin-bottom: 100px !important;
  }
  .mb-xxs-110 {
    margin-bottom: 110px !important;
  }
  .mb-xxs-120 {
    margin-bottom: 120px !important;
  }
  .mb-xxs-130 {
    margin-bottom: 130px !important;
  }
  .mb-xxs-140 {
    margin-bottom: 140px !important;
  }
  .mb-xxs-150 {
    margin-bottom: 150px !important;
  }
  .mb-xxs-160 {
    margin-bottom: 160px !important;
  }
  .mb-xxs-170 {
    margin-bottom: 170px !important;
  }
  .mb-xxs-180 {
    margin-bottom: 180px !important;
  }
  .mb-xxs-190 {
    margin-bottom: 190px !important;
  }
  .mb-xxs-200 {
    margin-bottom: 200px !important;
  }

  /*
 * 6.6 -> Margin left
 * -----------------------------------------------
*/
  .ml-xxs-0 {
    margin-left: 0 !important;
  }
  .ml-xxs-5 {
    margin-left: 5px !important;
  }
  .ml-xxs-10 {
    margin-left: 10px !important;
  }
  .ml-xxs-15 {
    margin-left: 15px !important;
  }
  .ml-xxs-20 {
    margin-left: 20px !important;
  }
  .ml-xxs-30 {
    margin-left: 30px !important;
  }
  .ml-xxs-40 {
    margin-left: 40px !important;
  }
  .ml-xxs-50 {
    margin-left: 50px !important;
  }
  .ml-xxs-60 {
    margin-left: 60px !important;
  }
  .ml-xxs-70 {
    margin-left: 70px !important;
  }
  .ml-xxs-80 {
    margin-left: 80px !important;
  }
  .ml-xxs-90 {
    margin-left: 90px !important;
  }
  .ml-xxs-100 {
    margin-left: 100px !important;
  }
  .ml-xxs-110 {
    margin-left: 110px !important;
  }
  .ml-xxs-120 {
    margin-left: 120px !important;
  }
  .ml-xxs-130 {
    margin-left: 130px !important;
  }
  .ml-xxs-140 {
    margin-left: 140px !important;
  }
  .ml-xxs-150 {
    margin-left: 150px !important;
  }
  .ml-xxs-160 {
    margin-left: 160px !important;
  }
  .ml-xxs-170 {
    margin-left: 170px !important;
  }
  .ml-xxs-180 {
    margin-left: 180px !important;
  }
  .ml-xxs-190 {
    margin-left: 190px !important;
  }
  .ml-xxs-200 {
    margin-left: 200px !important;
  }

  /*
 * 6.7 -> Padding top
 * -----------------------------------------------
*/
  .pt-xxs-0 {
    padding-top: 0 !important;
  }
  .pt-xxs-5 {
    padding-top: 5px !important;
  }
  .pt-xxs-10 {
    padding-top: 10px !important;
  }
  .pt-xxs-15 {
    padding-top: 15px !important;
  }
  .pt-xxs-20 {
    padding-top: 20px !important;
  }
  .pt-xxs-30 {
    padding-top: 30px !important;
  }
  .pt-xxs-40 {
    padding-top: 40px !important;
  }
  .pt-xxs-50 {
    padding-top: 50px !important;
  }
  .pt-xxs-60 {
    padding-top: 60px !important;
  }
  .pt-xxs-70 {
    padding-top: 70px !important;
  }
  .pt-xxs-80 {
    padding-top: 80px !important;
  }
  .pt-xxs-90 {
    padding-top: 90px !important;
  }
  .pt-xxs-100 {
    padding-top: 100px !important;
  }
  .pt-xxs-110 {
    padding-top: 110px !important;
  }
  .pt-xxs-120 {
    padding-top: 120px !important;
  }
  .pt-xxs-130 {
    padding-top: 130px !important;
  }
  .pt-xxs-140 {
    padding-top: 140px !important;
  }
  .pt-xxs-150 {
    padding-top: 150px !important;
  }
  .pt-xxs-160 {
    padding-top: 160px !important;
  }
  .pt-xxs-170 {
    padding-top: 170px !important;
  }
  .pt-xxs-180 {
    padding-top: 180px !important;
  }
  .pt-xxs-190 {
    padding-top: 190px !important;
  }
  .pt-xxs-200 {
    padding-top: 200px !important;
  }

  /*
 * 6.8 -> Padding right
 * -----------------------------------------------
*/
  .pr-xxs-0 {
    padding-right: 0 !important;
  }
  .pr-xxs-5 {
    padding-right: 5px !important;
  }
  .pr-xxs-10 {
    padding-right: 10px !important;
  }
  .pr-xxs-15 {
    padding-right: 15px !important;
  }
  .pr-xxs-20 {
    padding-right: 20px !important;
  }
  .pr-xxs-30 {
    padding-right: 30px !important;
  }
  .pr-xxs-40 {
    padding-right: 40px !important;
  }
  .pr-xxs-50 {
    padding-right: 50px !important;
  }
  .pr-xxs-60 {
    padding-right: 60px !important;
  }
  .pr-xxs-70 {
    padding-right: 70px !important;
  }
  .pr-xxs-80 {
    padding-right: 80px !important;
  }
  .pr-xxs-90 {
    padding-right: 90px !important;
  }
  .pr-xxs-100 {
    padding-right: 100px !important;
  }
  .pr-xxs-110 {
    padding-right: 110px !important;
  }
  .pr-xxs-120 {
    padding-right: 120px !important;
  }
  .pr-xxs-130 {
    padding-right: 130px !important;
  }
  .pr-xxs-140 {
    padding-right: 140px !important;
  }
  .pr-xxs-150 {
    padding-right: 150px !important;
  }
  .pr-xxs-160 {
    padding-right: 160px !important;
  }
  .pr-xxs-170 {
    padding-right: 170px !important;
  }
  .pr-xxs-180 {
    padding-right: 180px !important;
  }
  .pr-xxs-190 {
    padding-right: 190px !important;
  }
  .pr-xxs-200 {
    padding-right: 200px !important;
  }

  /*
 * 6.9 -> Padding bottom
 * -----------------------------------------------
*/
  .pb-xxs-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxs-5 {
    padding-bottom: 5px !important;
  }
  .pb-xxs-10 {
    padding-bottom: 10px !important;
  }
  .pb-xxs-15 {
    padding-bottom: 15px !important;
  }
  .pb-xxs-20 {
    padding-bottom: 20px !important;
  }
  .pb-xxs-30 {
    padding-bottom: 30px !important;
  }
  .pb-xxs-40 {
    padding-bottom: 40px !important;
  }
  .pb-xxs-50 {
    padding-bottom: 50px !important;
  }
  .pb-xxs-60 {
    padding-bottom: 60px !important;
  }
  .pb-xxs-70 {
    padding-bottom: 70px !important;
  }
  .pb-xxs-80 {
    padding-bottom: 80px !important;
  }
  .pb-xxs-90 {
    padding-bottom: 90px !important;
  }
  .pb-xxs-100 {
    padding-bottom: 100px !important;
  }
  .pb-xxs-110 {
    padding-bottom: 110px !important;
  }
  .pb-xxs-120 {
    padding-bottom: 120px !important;
  }
  .pb-xxs-130 {
    padding-bottom: 130px !important;
  }
  .pb-xxs-140 {
    padding-bottom: 140px !important;
  }
  .pb-xxs-150 {
    padding-bottom: 150px !important;
  }
  .pb-xxs-160 {
    padding-bottom: 160px !important;
  }
  .pb-xxs-170 {
    padding-bottom: 170px !important;
  }
  .pb-xxs-180 {
    padding-bottom: 180px !important;
  }
  .pb-xxs-190 {
    padding-bottom: 190px !important;
  }
  .pb-xxs-200 {
    padding-bottom: 200px !important;
  }

  /*
 * 6.10 -> Padding left
 * -----------------------------------------------
*/
  .pl-xxs-0 {
    padding-left: 0 !important;
  }
  .pl-xxs-5 {
    padding-left: 5px !important;
  }
  .pl-xxs-10 {
    padding-left: 10px !important;
  }
  .pl-xxs-15 {
    padding-left: 15px !important;
  }
  .pl-xxs-20 {
    padding-left: 20px !important;
  }
  .pl-xxs-30 {
    padding-left: 30px !important;
  }
  .pl-xxs-40 {
    padding-left: 40px !important;
  }
  .pl-xxs-50 {
    padding-left: 50px !important;
  }
  .pl-xxs-60 {
    padding-left: 60px !important;
  }
  .pl-xxs-70 {
    padding-left: 70px !important;
  }
  .pl-xxs-80 {
    padding-left: 80px !important;
  }
  .pl-xxs-90 {
    padding-left: 90px !important;
  }
  .pl-xxs-100 {
    padding-left: 100px !important;
  }
  .pl-xxs-110 {
    padding-left: 110px !important;
  }
  .pl-xxs-120 {
    padding-left: 120px !important;
  }
  .pl-xxs-130 {
    padding-left: 130px !important;
  }
  .pl-xxs-140 {
    padding-left: 140px !important;
  }
  .pl-xxs-150 {
    padding-left: 150px !important;
  }
  .pl-xxs-160 {
    padding-left: 160px !important;
  }
  .pl-xxs-170 {
    padding-left: 170px !important;
  }
  .pl-xxs-180 {
    padding-left: 180px !important;
  }
  .pl-xxs-190 {
    padding-left: 190px !important;
  }
  .pl-xxs-200 {
    padding-left: 200px !important;
  }
}
