.desktopStyle {
  // background-color: #000 !important;
  background-color: transparent !important;
  position: relative !important;
  display: block !important;
  box-shadow: none !important;

  .jh-container {
    align-items: center;
    justify-content: space-between;
    max-width: 1320px;
    margin: 0 auto;
    padding: 30px 0;
  }

  .flex-jh {
    display: flex;
  }

  .logo {
    width: 50px; 
    height: 50px;
    margin-top: 4px;
    margin-left: 10px;
  }

  a {
    text-decoration: none;
  }
  .MuiBox-root {
    display: flex;
    align-items: center;
  }

  .hyperlink {
    color: #fff;
    // font-size: 12px;
    text-transform: capitalize;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    font-feature-settings: "liga" off;
    color: #f2f2f2;

    &:hover {
      color: #bc1823;
    }
  }

  .hyperlink.builder-new-link {
    color: #fff;
  }

  .hyperlink.builder-new-link:hover {
    color:#bc1823;
    background-color: black;
  }
}

@media (min-width: 991px) {
  .MuiBox-root {
    display: flex !important;
    gap: 30px;
  }
}

@media (min-width: 991px) {
  .desktopStyle .builder-new-link {
    border-radius: 3px;
    padding: 10px 15px;
    font-weight: 600;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.199);

    width: 122px;
    height: 50px;

    background: #bc1823;
    border-radius: 8px;
  }
  .desktopStyle .builder-new-link:hover {
    background-color: #4168f5;
  }

  .desktopStyle .new-login-button {
    border: 2px solid #ffffff;
    border-radius: 3px;
    font-weight: 600;
    background-color: #1d8665;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.199);
    padding: 8px 13px;
  }
}

@media (max-width: 992px) {
  .desktopStyle .flex-jh {
    display: flex;
    justify-content: end;

    .logo {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      margin-top: 4px;
      margin-left: 5%;
    }

    button.btnMenuIcon {
      position: absolute;
    }
  }

  .css-1r7kz78-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
    width: 40% !important;
  }

  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: white !important;
  }

  // moileDrawer

  .moileDrawer {
    color: black !important;

    .hyperlink{
      height: 30px;
      width: 30px;
      text-decoration: none;
    }

    .builder-new-link {
      border-radius: 3px;
      padding: 10px 15px;
      font-weight: 600;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.212);
      width: 90px;
      height: 40px;
      background: #bc1823;
      border-radius: 8px;
      color: #fff;
      position: absolute;
      left: 5px;
      margin-top: 5px;
    }

    .logo {
      width: 35px;
      height: 35px;
      margin-left: -450%   !important;
      // margin: 0 auto;
    }

    .logoBlack{
      width: 38px;
      height: 38px;
      margin-left: -450%   !important;
    }

    .closeBtn {
      color: #bc1823;
      position: absolute;
      right: 0;
      top: 13px;
    }

    .drawerHeader {
      display: flex;
      justify-content: center;
    }

    // .top-right{
    //   position: absolute;
    //   width: 600px;
    //   height: 600px;
    //   left: 900px;
    //   top: -118px;
    //   border-radius: 0 0 500px 100%;
    //   background: rgba(255, 170, 170, 0.5);
    //   filter: blur(152px);
    // } 

  }
}

@media (max-width: 600px) {
  .desktopStyle {

    .menus{
      display: none;
    }
  }
}
